<template>
  <div class="input">
    <p v-if="label" class="input__label">{{label}}</p>

    <el-input :placeholder="placeholder" v-model="localValue"></el-input>
  </div>
</template>

<script>
import MixinVModel from "@/mixins/MixinVModel";

export default {
  name: "Input",

  mixins: [MixinVModel],

  components: {},

  props: {
    label: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    }
  },

  data: () => {
    return {};
  },

  created() {
    let query = this.$route.query;

    if (query && query.id) {
      this.localValue = query.id;
    }
  }
};
</script>

<style lang="scss">
.input {
  margin: 0.5rem 0;

  // .el-input__inner {
  //   color: #e1e1e1;
  //   background-color: hsla(0, 0%, 100%, 0.1);
  //   border-color: hsla(0, 0%, 100%, 0.15);
  // }

  &__label {
    margin-bottom: 0.5rem;
  }
}
</style>

