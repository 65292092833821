<template>
  <div class="actions">
    <CButton class="actions__button" v-if="isDelete" color="danger" @click="$emit('delete')">Delete</CButton>

    <CButton class="actions__button" color="warning" @click="$emit('edit')">Edit</CButton>
  </div>
</template>

<script>
export default {
  name: "Actions",

  props: {
    isDelete: {
      type: Boolean,
      default: true
    }
  }
};
</script>


<style lang="scss" scoped>
.actions {
  width: 100%;

  display: flex;

  &__button {
    flex: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
</style>