export default {
  data: () => ({
    filterData: {}
  }),

  computed: {
    filterDataHash() {
      return Object.values(this.filterData).join("-");
    }
  },

  created() {
    if (this.defaultFilferValue != {})
      this.filterData = { ...this.defaultFilferValue };
    else this.filterData = { ...this.filters.dataDefault };
  },

  watch: {
    filterDataHash() {
      console.log("filterDatafilterData");

      this.$emit("filfer", this.filterData);
    }
  },

  methods: {
    handlerClear() {
      this.filterData = { ...this.filters.dataDefault };
    }
  }
};
