import { axios, showNotification } from "../utils/axios";

export default {
  data: () => ({
    addictionsData: {}
  }),

  created() {
    if (this.addictions) this.loadAddictions({ addictions: this.addictions });
  },

  methods: {
    loadAddictions({ addictions }) {
      addictions.forEach(item => {
        const url = `/${this.apiName}/get/${item}/all`;

        axios
          .get(url)
          .then(response => {
            console.log(response);
            this.addictionsData[item] = response.data.results;
          })
          .catch(error => {
            console.log(error.response.data);
          });
      });
    }
  }
};
