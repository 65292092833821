<template>
  <CCard class="filter-container" slot="filters">
    <CCardBody>
      <CRow>
        <CCol
          v-for="(item, key) of items"
          :key="key"
          col="12"
          sm="4"
          md="4"
          lg="4"
          :xl="item.size || '2'"
        >
          <Input
            v-if="item.type == 'select'"
            v-model="filterData[key]"
            :label="item.label"
            :placeholder="item.label"
          />

          <MultiselectServerSearch
            v-if="item.type == 'complete'"
            v-model="filterData[key]"
            :label="item.label"
            :placeholder="item.label"
            :apiName="item.apiName"
            :data="options && options[item.optionName]"
          />

          <div class="filter-container__date" v-if="item.type == 'date'">
            <label>{{ item.label }}</label>
            <el-date-picker
              class="filter-container__date-el"
              v-model="filterData[key]"
              type="date"
              :placeholder="item.label"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
            />
          </div>
        </CCol>

        <CCol col="12" sm="4" md="4" lg="4" xl="2">
          <CButton
            class="filter-container__button"
            color="warning"
            @click="$emit('clear')"
          >Clear filters</CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import MultiselectServerSearch from "@/components/root/MultiselectServerSearch";
import Input from "@/components/root/Input";

export default {
  name: "FilterContainer",

  props: {
    items: {
      type: Object,
      default: () => {}
    },

    filterData: {
      type: Object,
      default: () => {}
    },

    options: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    MultiselectServerSearch,
    Input
  }

  // created() {
  //   if (this.filterData && this.filterData.phone_number > 0)
  //     console.log("filterData", this.filterData);
  // }
};
</script>



<style lang="scss" scoped>
.filter-container {
  width: 100%;

  &__button {
    width: 100%;
    height: 40px;

    margin-top: 36px;

    @media (max-width: 575.98px) {
      margin-top: 10px;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    width: 100%;
    margin: 0.5rem 0;
  }

  &__date-el {
    width: 100%;
  }
}
</style>