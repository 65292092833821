import { axios, showNotification } from "../utils/axios";

import Actions from "@/components/root/Actions";
import TableHeader from "@/components/root/TableHeader";
import addictionsMixin from "./addictions";
import screenFiltersMixin from "./screenFilters";

export default {
  components: {
    TableHeader,

    Actions
  },

  props: {},

  mixins: [addictionsMixin, screenFiltersMixin],

  data: () => ({
    apiKey: "category",

    activeItem: false,
    loading: false,

    prefetch: true,

    data: [],

    countItems: 0,
    countTotalItems: 0,
    page: 1,
    pageSize: 10,

    filterData: [],

    isSelectedAll: false,
    selected: [],
    selectType: "all",

    selectedMassUpdateModal: null,

    commaSeparated: {
      services: ["service"],
      // users: ["email"],
      "numbers/list": ["phone_number", "channel", "port"]
      // orders: ["id"],
      // sms: ["id"]
    }
  }),

  computed: {
    countPages() {
      return Math.ceil(this.countItems / this.pageSize);
    },

    countSelectedItems() {
      return this.isSelectedAllMatching
        ? this.countItems
        : this.selected.length;
    },

    isSelectedAllMatching() {
      return this.isSelectedAll && this.selectType === "matching";
    },

    filtersStr() {
      let url = "";

      for (let key in this.filterData) {
        let items = this.filterData[key];

        if (items) {
          if (typeof items == "string") {
            console.log(`${key}`, items);

            if (key == "created_at") {
              url += `created_at_before=${items}&created_at_after=${items}&`;
            } else if (items) url += `${key}=${items}&`;
          } else if (items.length > 0) {
            let commaSeparated = this.commaSeparated[this.apiName];

            if (commaSeparated && commaSeparated.indexOf(key) != -1) {
              console.log(key);
              let param = `${key}=`;

              for (let item of items) {
                param += `${item},`;
              }

              url += param + "&";
            } else
              for (let item of items) {
                url += `${key}=${item}&`;
              }
          }
        }
      }

      return url;
    }
  },

  watch: {
    pageSize() {
      this.page = 1;
      this.loadData();
    }
  },

  created() {
    this.$watch(
      "page",
      () => {
        this.loadData();
      },
      {
        immediate: this.prefetch
      }
    );
  },

  methods: {
    loadData() {
      let url = `${this.apiName}/get/${this.apiKey}/all?${this.filtersStr}`;

      this.loading = true;

      console.log(url);

      axios
        .get(url, {
          params: {
            limit: this.pageSize,
            offset: this.page * this.pageSize - this.pageSize
          }
        })
        .then(response => {
          console.log(JSON.stringify(response.datas));
          console.log("response.data");
          console.log({
            limit: this.pageSize,
            offset: this.page * this.pageSize - this.pageSize
          });
          // throw 401;

          this.data = response.data.results;
          this.countItems = response.data.count;
          if (this.countTotalItems == 0)
            this.countTotalItems = response.data.count;

          this.loading = false;
        })
        .catch(error => {
          // console.log(error.response.data);

          this.loading = false;
        });
    },

    handlerDelete(item) {
      const agreement = confirm("Are you sure you want to delete this item ?");

      if (agreement) {
        const url = `${this.apiName}/delete/${this.apiKey}/${item.id}`;

        axios
          .delete(url)
          .then(response => {
            this.loadData();

            showNotification({
              type: "success",
              text: `Item id ${item.id} successfully deleted`
            });
          })
          .catch(error => {
            console.log(error.response.data);

            showNotification({
              type: "danger",
              text: `Error! Failed to delete item with id ${item.id}`
            });
          });
      }
    },

    handlerSubmitModal() {
      console.log("submit");
      this.loadData();
    },

    handlerFilter(data) {
      // let url = `/admin_api/${this.apiName.split("/")[0]}?`;

      this.page = 1;
      this.filterData = data;

      this.loadData();
    },

    handlerChoseAll() {
      this.isSelectedAll = !this.isSelectedAll;

      if (!this.isSelectedAll) this.selected = [];
      else {
        const _selected = this.selected;

        for (let item of this.data) {
          _selected.push(item.id);
        }

        this.selected = _selected;
      }
    },

    handlerChose(id) {
      let data = [];
      let index = this.selected.indexOf(id);

      if (index == -1) this.selected.push(id);
      else {
        for (let item of this.selected) {
          if (item != id) data.push(item);
        }

        this.selected = data;
      }

      if (this.selected.length == 10) this.isSelectedAll = true;
      else this.isSelectedAll = false;
    },

    checkValue(id) {
      return this.selected.indexOf(id) != -1;
    }
  }
};
