<template>
  <div class="multiselect-wraper">
    <p v-if="label" class="multiselect-wraper__label">{{ label }}</p>

    <div class="multiselect-wraper__content">
      <el-select
        style="width: 100%"
        v-model="localValue"
        :label="localValue"
        :multiple="multiple"
        filterable
        :remote="remote"
        reserve-keyword
        :placeholder="placeholder"
        :remote-method="remoteMethod"
        :loading="loading"
        ref="el"
        @input="handlerInput"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>

      <span
        v-if="close"
        class="multiselect-wraper__close"
        @click="localValue = ''"
        >×</span
      >
    </div>
  </div>
</template>

<script>
import { axios, showNotification } from "../../utils/axios";

import MixinVModel from "@/mixins/MixinVModel";

import $ from "jquery";

export default {
  name: "Multiselect",

  mixins: [MixinVModel],

  components: {},

  props: {
    data: {
      type: Array,
      default: () => []
    },

    label: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: "Please enter a keyword"
    },

    apiName: {
      type: String,
      default: null
    },

    multiple: {
      type: Boolean,
      default: true
    },

    preLoad: {
      type: Boolean,
      default: false
    },

    close: {
      type: Boolean,
      default: false
    },

    remote: {
      type: Boolean,
      default: true
    }
  },

  data: () => {
    return {
      // options: [],
      value: [],
      list: [],
      loading: false,
      states: []
    };
  },

  created() {
    // if (!this.apiName) this.states = this.data;

    if (!this.apiName) this.list = this.data;

    if (this.data && typeof this.data[0] !== "object")
      this.list = this.data.map(item => {
        return { value: `${item}`, label: `${item}` };
      });

    this.options = this.list;

    if (this.apiName == "numbers" && this.$route.query.phone_number_label)
      this.search(this.$route.query.phone_number_label);

    if (this.preLoad) this.search(this.localValue);
    // this.localValue = [95];
  },

  methods: {
    handlerInput() {
      // $(this.$refs.el).val("");
      // console.log(
      //   ($(this.$refs.el.$el)
      //     .find(".el-select__input")
      //     .get(0).value = "hello")
      // );
      // console.log("fff", $(this.$refs.el));
      // console.log("DDd");
    },

    remoteMethod(query) {
      // console.log(query);

      if (query) {
        if (this.apiName) this.search(query);
      } else {
        this.options = this.list;
      }
    },

    search(query) {
      let apiName = this.apiName == "emails" ? "users" : this.apiName;

      this.loading = true;

      axios
        .get(`/admin_api/${apiName}/?search=${query}`, {
          params: {}
        })
        .then(response => {
          this.loading = false;

          if (this.apiName == "stations") {
            let options = [];

            for (let item of response.data) {
              options.push({
                value: item.id,
                label: item.name
              });
            }

            this.options = options;
          } else this.options = response.data;

          // this.localValue = [95];

          console.log(response.data);
        })
        .catch(error => {
          console.log(error);

          showNotification({
            type: "danger",
            text: `${error}`
          });
        });
    }
  }
};
</script>

<style lang="scss">
.multiselect-wraper {
  margin: 0.5rem 0;

  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
  }

  &__label {
    margin-bottom: 0.5rem;
  }

  &__close {
    margin-left: 1rem;

    font-size: 1.3125rem;
    font-weight: 700;
    color: #000015;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;

    cursor: pointer;
  }
}
</style>
