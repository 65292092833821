<template>
  <CModal
    :class="['app-modal', mods]"
    :title="title"
    :show="show"
    :closeOnBackdrop="false"
    @update:show="$emit('cansel')"
    @accepted="$emit('accepted')"
    centered
    ok-variant="danger"
  >
    <!-- <div style="width: 80vw"> -->
    <slot />

    <ErrorView v-if="errors" :data="errors" class="app-modal-error" />

    <template slot="footer">
      <div class="app-modal-footer">
        <div class="app-modal-footer__btn">
          <CButton color="secondary" @click="$emit('cansel')">Cancel</CButton>
        </div>

        <div class="app-modal-footer__btn">
          <CButton color="primary" @click="!loading && $emit('ok')">OK</CButton>
        </div>
      </div>
    </template>
    <!-- </div> -->
  </CModal>
</template>

<script>
import ErrorView from "@/components/root/ErrorView";

export default {
  name: "modal",

  components: {
    ErrorView
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },

    loading: {
      type: Boolean,
      default: false
    },

    errors: {
      type: Object,
      default: null
    },

    full: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    mods() {
      return {
        "app-modal_full": this.full
      };
    }
  }
};
</script>

<style lang="scss">
.app-modal {
  &_full {
    .modal-dialog {
      max-width: 90vw;

      @media (min-width: 1200px) {
        max-width: 70vw !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.app-modal-footer {
  display: flex;
  align-items: center;

  &__btn {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.app-modal-error {
  margin-top: 1rem;
}
</style>