<template>
  <div class="error-view">
    <div class="error-view__group" v-for="(group, key) of data" :key="key">
      <p class="error-view__title">{{ key }}:</p>

      <p class="error-view__item" v-if="key != 'message'">{{ group }}</p>

      <p class="error-view__item" v-else v-for="(item, index) of group" :key="index">{{ item }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "error-view",

  props: {
    data: {
      type: Object,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.error-view {
  &__group {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__title {
    margin-bottom: 0.2rem;

    font-size: 0.85rem;
  }

  &__item {
    margin: 0;

    font-size: 0.75rem;
    color: #d55d59;
  }
}
</style>