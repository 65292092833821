import "vue-loading-overlay/dist/vue-loading.css";

import { axios, showNotification } from "../utils/axios";

import Loading from "vue-loading-overlay";
import Modal from "@/components/root/Modal";
import Vue from "vue";
import moment from "moment";

// Init plugin
Vue.use(Loading);

export default {
  components: {
    Modal
  },

  props: {
    data: {
      type: [Object, Boolean, Array],
      default: null
    },

    isShow: {
      type: Boolean,
      default: false
    },

    apiName: {
      type: String
    },
    apiKey: {
      type: String,
      default: "category"
    },

    addictionsData: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    autoComplete: true,
    loading: false,

    errors: null,

    sendPresets: ["presetium", "filteresco", "powerpoint"],
    files: [
      "preset",
      "image",
      "imageHorizontal",
      "preview",
      "keynote",
      "powerpoint",
      "other",
      "font",
      "fontSecond",
      "canva",
      "image1",
      "image2",
      "image3",
      "image4",
      "image5"
    ]
  }),

  watch: {
    isShow(next) {
      if (this.autoComplete) {
        if (next) {
          this.errors = null;
          const newForm = { ...this.form };
          const formKeys = Object.keys(this.form);

          if (this.data) {
            for (let key of formKeys) {
              console.log(key);

              if (key == "created_at") {
                newForm[key] = moment(this.data[key])
                  .subtract("hours", 3)
                  .format("YYYY-MM-DD HH:mm:ss");
              } else newForm[key] = this.data[key];
            }
            // formKeys.forEach(key => {
            // if (key == "created_at") {
            //   newForm[key] = moment(newForm[key])
            //     .subtract("hours", 3)
            //     .format("YYYY-MM-DD hh:mm:ss");
            // } else newForm[key] = this.data[key];
            // });
          } else {
            formKeys.forEach(key => {
              if (key == "presets")
                newForm[key] = [
                  {
                    reference: null,
                    image: null,
                    preset: null,
                    preview: null,
                    free: false,
                    free_android: false,
                    show: false,
                    show_button: true,
                    curtain: true,
                    order: 0
                  }
                ];
              else if (key == "count_download" || key == "count_open") {
                newForm[key] = 0;
              } else if (key == "created_at") {
                newForm[key] = moment()
                  .subtract("hours", 3)
                  .format("YYYY-MM-DD HH:mm:ss");
              } else if (key == "settings") {
                newForm[key] = {};
              } else if (key == "tags") {
                newForm[key] = [];
              } else if (
                key == "free" ||
                key == "free_android" ||
                key == "premium" ||
                key == "recommended" ||
                key == "recent" ||
                key == "show_in_slider" ||
                key == "big"
              ) {
                newForm[key] = false;
              } else if (
                key == "show" ||
                key == "show_button" ||
                key == "curtain"
              ) {
                newForm[key] = true;
              } else if (key == "order") {
                newForm[key] = 0;
              } else if (key == "filter") {
                newForm[key] = "none";
              } else if (key == "filter_setting") {
                newForm[key] = 12;
              } else if (key !== "free") {
                newForm[key] = null;
              }
            });
          }

          this.form = newForm;
        }
      }
    }
  },

  methods: {
    handlerSubmit() {
      console.log("load");
      if (!this.loading) {
        this.sendForm();
      }
    },

    editDataItem() {
      console.log(this.form);
    },

    sendForm() {
      this.errors = null;

      const isEdit = !!this.data;

      let url = `${this.apiName}/${isEdit ? "update" : "create"}/${
        this.apiKey
      }`;

      if (isEdit) url += `/${this.data.id}`;

      let method = null;

      if (this.fetchMethods) method = this.fetchMethods;
      else method = isEdit ? "post" : "post";

      this.loading = true;

      const _form = this.form;

      let formData = new FormData();

      Object.keys(_form).forEach(key => {
        const item = _form[key];

        if (key == "settings" || key == "tags") {
          formData.append(key, JSON.stringify(item));
        } else if (
          item !== null &&
          key != "presets" &&
          this.files.indexOf(key) == -1
          // key != "preset" &&
          // key != "image"
        ) {
          // if (item !== null) {
          formData.append(key, item);
        }
      });

      for (let file of this.files) {
        console.log("_form[file]", _form[file]);

        if (
          _form[file] &&
          (_form[file].type == "edit" || _form[file].type == "upload") &&
          _form[file].file
        ) {
          if (file == "other" || file == "font" || file == "fontSecond") {
            let names = _form[file].file.name.split(".");
            formData.append(`${file}_extension`, names[names.length - 1]);
            // console.log("other", names[names.length - 1]);
          }

          formData.append(file, _form[file].file);
        }
      }

      // if (
      //   _form.preset &&
      //   (_form.preset.type == "edit" || _form.preset.type == "upload") &&
      //   _form.preset.file
      // )
      //   formData.append("preset", _form.preset.file);

      // if (
      //   _form.image &&
      //   (_form.image.type == "edit" || _form.image.type == "upload") &&
      //   _form.image.file
      // )
      //   formData.append("image", _form.image.file);

      // delete _form.presets;

      // formData.append("presets", _form.presets[0].reference.file);

      console.log("_form", JSON.stringify(_form));

      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          console.log("response.data", response.data);

          // if (!isEdit) this.form.id = response.data.id;

          showNotification({
            type: "success",
            text: isEdit
              ? `Item id ${this.data.id} successfully edited`
              : "Item successfully add"
          });

          if (this.form.presets && this.form.presets.length > 0) {
            this.handlerSubmitPresets(response.data.id || this.data.id);
          } else {
            this.endSuccessSend();
          }
        })
        .catch(error => {
          console.log(error);
          console.log(error.response.data);

          this.errors = error.response.data;
          // console.log(error.response.data);

          showNotification({
            type: "danger",
            text: isEdit
              ? `Error! Failed to edit item with id ${this.data.id}`
              : "Error! Failed to add item"
          });

          // loader.hide();
          this.loading = false;
        });
    },

    getDownloadPresets(data) {},

    handlerSubmitPresets(id) {
      console.log("handlerSubmitImage");
      let countElements = this.form.presets.length;
      let successSend = 0;

      for (let key in this.form.presets) {
        let item = this.form.presets[key];
        let isEdit = item.type == "edit";
        let url = null;
        let method = "post";

        console.log("item", item);
        console.log("id", id);
        console.log("this.data", this.data);

        if (
          item &&
          (item.reference || item.image || item.preset) &&
          // (this.sendPresets.indexOf(this.apiName) != -1 || item.preset) &&
          (!item.id || item.type == "edit")
        ) {
          let formData = new FormData();

          console.log("11111", !item.id || item.image.type == "upload");
          // console.log("free", item.free);

          formData.append("category_id", id);

          if (item.free != null) formData.append("free", item.free);
          if (item.show != null) formData.append("show", item.show);
          if (item.free_android != null)
            formData.append("free_android", item.free_android);
          if (item.name != null) formData.append("name", item.name);
          if (item.size != null) formData.append("size", item.size);
          if (item.link != null) formData.append("link", item.link);
          if (item.show_button != null) formData.append("show_button", item.show_button);
          if (item.curtain != null) formData.append("curtain", item.curtain);
          if (item.unique_text_show != null) formData.append("unique_text_show", item.unique_text_show);
          if (item.unique_text_show) {
            formData.append("unique_text_en", item.unique_text_en);
            formData.append("unique_text_ru", item.unique_text_ru);
            formData.append("unique_text_fr", item.unique_text_fr);
            formData.append("unique_text_es", item.unique_text_es);
            formData.append("unique_text_de", item.unique_text_de);
            formData.append("unique_text_it", item.unique_text_it);
          }


          formData.append("order", item.order);

          // this.sendPresets.indexOf(this.apiName) == -1 &&
          if (
            item.reference &&
            (item.reference.type == "edit" ||
              item.reference.type == "upload") &&
            item.reference.file
          )
            formData.append("reference", item.reference.file);
          if (
            item.image &&
            (item.image.type == "edit" || item.image.type == "upload") &&
            item.image.file
          )
            formData.append("image", item.image.file);
          if (
            // this.sendPresets.indexOf(this.apiName) == -1 &&
            // this.apiName != "presetium" &&
            // this.apiName != "filteresco" &&
            item.preset &&
            (item.preset.type == "edit" || item.preset.type == "upload") &&
            item.preset.file
          )
            formData.append("preset", item.preset.file);

          console.log("create/preset");

          if (item.id && isEdit) {
            url = `${this.apiName}/update/preset/${item.id}`;
          } else url = `${this.apiName}/create/preset`;

          axios
            .post(url, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(response => {
              console.log("successfully add", response.data);

              this.form.presets[key] = response.data.data;

              showNotification({
                type: "success",
                text: `Image successfully add`
              });

              successSend += 1;

              if (successSend == countElements) {
                this.endSuccessSend();
              }
            })
            .catch(error => {
              successSend += 1;

              if (successSend == countElements) {
                this.endSuccessSend();
              }

              console.log(error);
              console.log(error.response.data);
            });
        } else {
          successSend += 1;

          if (successSend == countElements) {
            this.endSuccessSend();
          }
        }
      }
    },

    endSuccessSend() {
      this.loading = false;

      this.$emit("close");
      this.$emit("submit");
    },

    handlerDeletePreset({ id, cb }) {
      console.log("handlerDeleteImage");

      const agreement = confirm("Are you sure you want to delete this item ?");

      if (agreement) {
        axios
          .delete(`${this.apiName}/delete/preset/${id}`)
          .then(response => {
            this.$emit("onChage");

            cb();

            showNotification({
              type: "success",
              text: `Image successfully delete`
            });
          })
          .catch(error => {
            // this.isLoading = false;
            console.log(error);
            console.log(error.response.data);
          });
      }
    },

    deleteFile(key) {
      console.log("handlerDeleteImage");

      const agreement = confirm("Are you sure you want to delete this item ?");

      if (agreement) {
        axios
          .post(`${this.apiName}/delete/category/file/${this.data.id}`, {
            key
          })
          .then(response => {
            this.$emit("submit");

            this.form[key] = null;

            showNotification({
              type: "success",
              text: `File ${key} successfully delete`
            });
          })
          .catch(error => {
            // this.isLoading = false;
            console.log(error);
            console.log(error.response.data);
          });
      }
    },

    getOptions(data) {
      return Object.values(data).map(item => item.name);
    }
  }
};
