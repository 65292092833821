<template>
  <div class="the-content">
    <CRow v-if="filters && filters.items">
      <CCol col="12">
        <div class="the-content__header">
          <!-- <slot name="filters"/> -->
          <FilterContainer
            :items="filters.items"
            :filterData="filterData"
            :options="filters.options"
            @clear="handlerClear()"
          />
        </div>
      </CCol>
    </CRow>

    <!-- <CRow v-if="$slots.header || $slots.action">
      <CCol col="12">
        <div class="the-content__header" v-if="$slots.header">
          <slot name="header"/>
        </div>

        <div class="the-content__action" v-if="$slots.action">
          <slot name="action"/>
        </div>
      </CCol>
    </CRow>-->

    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader v-if="title">
            <div class="the-content__tabel-header tabel-header">
              <span class="tabel-header__title">{{ title }}</span>

              <!-- <span
                class="tabel-header__count-items"
              >{{ countItems }} / {{countTotalItems}} resources</span>-->

              <AppSlect
                class="tabel-header__select"
                :value="pageSize"
                :options="statusOptions"
                @input="value => $emit('update:pageSize', value)"
              />
            </div>

            <div v-if="$slots.header || $slots.action">
              <div class="the-content__header" v-if="$slots.header">
                <slot name="header" />
              </div>

              <div class="the-content__action" v-if="$slots.action">
                <slot name="action" />
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <slot />
          </CCardBody>

          <CPagination
            v-if="pages > 1"
            :activePage="activePage"
            :pages="pages"
            align="center"
            @update:activePage="value => $emit('update:activePage', value)"
          />
        </CCard>
      </CCol>
    </CRow>

    <slot name="modals" />
  </div>
</template>

<script>
import FilterContainer from "@/components/root/FilterContainer";

import MixinsScreenFilters from "@/mixins/screenFilters";

export default {
  name: "the-container",

  mixins: [MixinsScreenFilters],

  props: {
    title: {
      type: String
    },

    activePage: {
      type: Number,
      default: 0
    },

    pages: {
      type: Number,
      default: 0
    },

    pageSize: {
      type: Number,
      default: 10
    },

    countTotalItems: {
      type: Number,
      default: 0
    },

    countItems: {
      type: Number,
      default: 0
    },

    countSelectedItems: {
      type: Number,
      default: 0
    },

    filters: {
      type: Object,
      default: () => {}
    },

    defaultFilferValue: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    FilterContainer
  },

  data: () => {
    return {
      statusOptions: [
        { value: 10, label: "10" },
        { value: 50, label: "50" },
        { value: 150, label: "150" },
        { value: 750, label: "750" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.the-content {
  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    // margin-bottom: 15px;
  }

  .tabel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575px) {
      flex-wrap: wrap;
    }

    &__title {
      margin-right: auto;
    }

    &__count-items {
      @media (max-width: 575px) {
        order: 1;
        width: 100%;
        margin-top: 0.5rem;
      }
    }

    &__select {
      margin: 0;
      margin-left: 1rem;
      width: 100px;
    }
  }
}
</style>
